import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import {
    makeStyles,
    Container,
    FormControl,
    TextField,
    Button
} from '@material-ui/core'

import Axios from 'axios'
import {
    LOGIN_URL
} from '../../constants/urls'


const useStyle = makeStyles(theme => ({
    root: {
        zIndex: -100,
        position: 'fixed',
        top: 0
    },
    form: {
        position: 'fixed',
        top: '20%',
        left: '30%',
        width: '30em',
        padding: theme.spacing(4),
        backgroundColor: theme.palette.primary.dark,
        [theme.breakpoints.only('lg')]: {
            left: '38%',
        }
    },
    inputBox: {
        marginBottom: theme.spacing(4)
    }
}))

export default props => {
    const classes = useStyle()
    const [uid, setUid] = useState('')
    const [password, setPassword] = useState('')
    const history = useHistory()
    return (
        <div>
            <Container className={classes.inputBox}>
                <FormControl fullWidth>
                    <TextField
                        type="text"
                        color="secondary"
                        variant='outlined'
                        label="uid"
                        value={uid}
                        onChange={(event) => {
                            setUid(event.target.value)
                        }}
                    />
                </FormControl>
            </Container>

            <Container className={classes.inputBox}>
                <FormControl fullWidth>
                    <TextField
                        type="password"
                        variant='outlined'
                        label="password"
                        value={password}
                        onChange={(event) => {
                            setPassword(event.target.value)
                        }}
                    />
                </FormControl>
            </Container>

            <Container>
                <FormControl fullWidth>
                    <Button
                        color="secondary"
                        variant="contained"
                        onClick={() => {
                            if (uid.trim() === '' && password.trim() === '') {
                                return
                            }

                            Axios({
                                method: 'POST',
                                url: LOGIN_URL,
                                data: {
                                    uid: uid.trim(),
                                    password: password.trim()
                                }
                            }).then(res => {
                                localStorage.setItem('cuchat-token', res.data.token)
                                localStorage.setItem('uid',res.data.uid)
                                history.push('/')
                            }).catch(err => {
                                console.log('login', err)
                                alert('There is something wrong')
                            })
                        }}
                    >Login</Button>
                </FormControl>
            </Container>

        </div>
    )
}