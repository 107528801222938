import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch
} from 'react-router-dom'
import Index from './pages';
import Auth from './pages/auth';
import Landing from './pages/landing';
import Chat from './pages/chat'

const App = props => {
  return (
    <div>
      <Router>
        <Switch>
          <Route exact path="/" >
            {(localStorage.getItem('cuchat-token') ? <Index /> : <Landing />)}
          </Route>
        </Switch>

        <Switch>
          <Route exact path="/chat/:uid" >
            <Chat />
          </Route>
        </Switch>

        <Switch>
          <Route exact path="/auth" >
            <Auth />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
