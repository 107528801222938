import React from 'react'
import { useParams, useHistory } from 'react-router-dom'
import io from 'socket.io-client'

import Text from '../components/chat/texts'

import {
    BASE_URL
} from '../constants/urls'

import {
    makeStyles,
    AppBar,
    Toolbar,
    Typography,
    IconButton,
    FormControl,
    TextField,
    Grid,
    Container,
    Tooltip
} from '@material-ui/core'

import {
    ArrowBack,
    Send
} from '@material-ui/icons'

const useStyle = makeStyles(theme => ({
    text: {
        margin: '0px',
        top: 'auto',
        right: '10px',
        bottom: '10px',
        left: '0px',
        position: 'fixed',
        width: '100%',
        padding: theme.spacing(1)
    },
    chatArea: {
        overflow: 'hidden scroll',
        height: '500px'
    }
}))

let socket;

const Chat = props => {
    let { uid } = useParams()
    const classes = useStyle()
    const location = useHistory()

    let [text, setText] = React.useState('')
    let [messages, setMessages] = React.useState([])

    React.useEffect(() => {
        socket = io(BASE_URL)

        socket.on(localStorage.getItem('uid'), message => {
            setMessages([...messages, message])
        })
    }, [messages])

    return (
        <div>
            <AppBar position="static">
                <Toolbar>
                    <IconButton
                        color="inherit"
                        onClick={() => {
                            location.goBack()
                        }}
                    >
                        <ArrowBack />
                    </IconButton>
                    <Typography>
                        {uid}
                    </Typography>
                </Toolbar>
            </AppBar>

            <div className={classes.chatArea}>
                <Container>
                    {messages.map(el => <Text message={el.message} from={el.from} />)}
                </Container>
            </div>

            <div className={classes.text}>
                <Container>
                    <Grid container spacing={3}>
                        <Grid item xs={11}>

                            <FormControl fullWidth>
                                <TextField
                                    variant="outlined"
                                    type="text"
                                    label="text"
                                    value={text}
                                    onChange={(event) => {
                                        setText(event.target.value)
                                    }}
                                    onKeyPress={(event) => {
                                        if (event.key === 'Enter') {
                                            if (text.trim() === '') {
                                                return;
                                            }
                                            setText('')
                                            let mes = {
                                                to: uid,
                                                from: localStorage.getItem('uid'),
                                                message: text
                                            }
                                            socket = io(BASE_URL)
                                            socket.emit('message', mes)
                                            setMessages([...messages, mes])
                                        }
                                    }}
                                />
                            </FormControl>

                        </Grid>

                        <Grid item xs={1}>
                            <IconButton
                                color="primary"
                                size="medium"
                                onClick={() => {
                                    if (text.trim() === '') {
                                        return;
                                    }
                                    setText('')
                                    let mes = {
                                        to: uid,
                                        from: localStorage.getItem('uid'),
                                        message: text
                                    }
                                    socket = io(BASE_URL)
                                    socket.emit('message', mes)
                                    setMessages([...messages, mes])

                                }}
                            >
                                <Tooltip title="send">
                                    <Send />
                                </Tooltip>
                            </IconButton>
                        </Grid>

                    </Grid>
                </Container>
            </div>
        </div>
    )
}


export default Chat