import React from 'react'
import Particles from 'react-particles-js'

import {
    makeStyles,
    Container,
    Card,
    Box,
    Typography,
    AppBar,
    Tab,
    Tabs,
} from '@material-ui/core'

import Signup from '../components/auth/signup'
import Login from '../components/auth/login'

const useStyle = makeStyles(theme => ({
    root: {
        zIndex: -100,
        position: 'fixed',
        top: 0
    },
    form: {
        position: 'fixed',
        top: '20%',
        left: '30%',
        width: '30em',
        padding: theme.spacing(4),
        backgroundColor: theme.palette.primary.dark,
        [theme.breakpoints.only('lg')]: {
            left: '38%',
        },
        [theme.breakpoints.down('sm')]: {
            left: '2%',
            right: '2%',
            width: '20em',
        }
    },
    inputBox: {
        marginBottom: theme.spacing(4)
    }
}))

const TabPanel = props => {
    const { children, value, index, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

const Auth = props => {

    const classes = useStyle()
    const [value, setValue] = React.useState(0)

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };

    return (
        <div >
            <div className={classes.root}>
                <Particles
                    className={classes.particle}
                    style={{ position: "fixed", }}
                    height="100%"
                    width="100%"
                    params={{
                        particles: {
                            color: {
                                value: "#e84a5f"
                            },
                            line_linked: {
                                color: {
                                    value: "#ff847c"
                                }
                            },
                            number: {
                                value: 80
                            },
                            size: {
                                value: 5
                            }
                        },
                        background: {
                            color: '#2a363b'
                        }
                    }}
                />
            </div>
            <Container >
                <Card
                    className={classes.form} variant="outlined">

                    <AppBar position="static" color="secondary">
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="fullWidth"
                            aria-label="full width tabs example"
                        >
                            <Tab label="Login" {...a11yProps(0)} />
                            <Tab label="Signup" {...a11yProps(1)} />
                        </Tabs>
                    </AppBar>
                    <TabPanel value={value} index={0}>
                        <Login />
                        </TabPanel>
                    <TabPanel value={value} index={1}>
                        <Signup />
                        </TabPanel>

                </Card>
            </Container>

        </div>
    )
}

export default Auth