import React from 'react'
import {useHistory} from 'react-router-dom'

import {
    makeStyles,
    Fab,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    FormControl,
    TextField,
    DialogActions,
    Button
} from '@material-ui/core'

import {
    Add
} from '@material-ui/icons'

const useStyle = makeStyles(theme => ({
    root: {
        margin: '0px',
        top: 'auto',
        right: '20px',
        bottom: '20px',
        left: 'auto',
        position: 'fixed'
    }
}))

const UidAdd = props => {
    const classes = useStyle()
    const [open, setOpen] = React.useState(false)
    const location = useHistory()
    const [uid, setUid] = React.useState('')

    return (
        <div className={classes.root}>
            <Fab
                color="primary"
                onClick={() => {
                    setOpen(true)
                }}
            >
                <Add />
            </Fab>

            <Dialog
            open={open}
            >
            <DialogTitle>
                Enter the uid of the person you wanna text. 
            </DialogTitle>

            <DialogContent>
                <FormControl fullWidth> 
                    <TextField 
                    variant="outlined" 
                    label="uid" 
                    value={uid}
                    onChange={(event) => {
                        setUid(event.target.value)
                    }}
                    />
                </FormControl>
                
            </DialogContent>

            <DialogActions>
                <Button
                onClick={() => {
                    if(uid.trim() === '') {
                        return 
                    }
                    location.push(`/chat/${uid}`)
                }}
                >
                    Chat
                </Button>
                <Button
                onClick={() => {
                    setOpen(false)
                }}
                >
                    cancel
                </Button>
            </DialogActions>

            </Dialog>
        </div>
    )
}

export default UidAdd