import {createMuiTheme} from '@material-ui/core'

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#222831',
            dark: '#ececec'
        },
        secondary: {
            main: '#f2a365',
            dark: '#30475e'
        },
        background: {
            paper: '#ececec'
        }
    },
    typography: {
        fontFamily: ['Montserrat', 'Roboto', 'Anton', 'Metal Mania'].join(',')
    }
})

export default theme