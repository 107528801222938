import React from 'react'
import {useHistory} from 'react-router-dom'

import {
    makeStyles,
    AppBar,
    Toolbar,
    Typography,
    Button,
    IconButton,
    Hidden
} from '@material-ui/core'
import {
    Menu
} from '@material-ui/icons'

const useStyle = makeStyles(theme => ({
    root: {
        flexGrow: 1
    },
    title: {
        flexGrow: 1
    }
}))

const Navbar = props => {
    const classes = useStyle()
    const history = useHistory()
    return (
        <div className={classes.root}>
            <AppBar variant="outlined" position="static">
                <Toolbar>
                    <Hidden mdUp>
                        <IconButton color="inherit">
                            <Menu />
                        </IconButton>
                    </Hidden>
                    <Typography className={classes.title}>
                        CU Chat
                    </Typography>
                    {(localStorage.getItem('cuchat-token') ? <Button 
                    color="inherit"
                    onClick={() => {
                        localStorage.removeItem('cuchat-token')
                        window.location.reload()
                    }}
                    >
                        Logout
                    </Button> : <Button 
                    color='inherit'
                    onClick={() => {history.push('/auth')}}
                    >
                        Login
                        </Button>)}
                </Toolbar>
            </AppBar>

        </div>
    )
}

export default Navbar