// actions types 
import {
    FETCH_USERS_TEXTED
} from '../constants'

const initialState = {
    uids: []
}


export default (state = initialState, action) => {
    switch(action.type){

        case FETCH_USERS_TEXTED:
            return {
                ...state,
                uids: action.payload
            }

        default:
            return state
    }
}