import React from 'react'
import Navbar from '../components/navbar'
import io from 'socket.io-client'
import { connect } from 'react-redux'

// components 
import Uid from '../components/rooms/uids'
import UidAdd from '../components/floatingbtn/uidadd'

// importing actions 
import {
    fetchUsersTextedAsync
} from '../store/actions/chat'

// material ui components 
import {
    makeStyles,
    Container,
    Snackbar
} from '@material-ui/core'

import {
    BASE_URL
} from '../constants/urls'

// styles 
const useStyle = makeStyles(theme => ({
    root: {

    }
}))

let socket;

const Index = props => {
    const classes = useStyle()
    let token = localStorage.getItem('cuchat-token')
    let uid = localStorage.getItem('uid')
    let [chats, setChats] = React.useState([])
    let [snackBar, setSnackbar] = React.useState(false)
    let [message, setMessage] = React.useState('')

    const snackBarHandleClose = () => {
        setSnackbar(!snackBar)
    }

    React.useEffect(() => {
        socket = io(BASE_URL)
        socket.emit('join', { uid: uid })

        socket.on('joined', (connUser) => {
            setMessage(`${connUser} in online`)
            setSnackbar(true)
        })
        socket.on('left', (connUser) => {
            setMessage(`${connUser} in offline`)
            setSnackbar(true)
        })

        return () => {
            socket.emit('disconnect', { uid: uid })
        }
    }, [])

    props.fetchUids(uid, token)
    
    return (
        <div>
            <Navbar />
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                autoHideDuration={2000}
                open={snackBar}
                onClose={snackBarHandleClose}
                message={message}
            />

            <Container>
                {props.uids.map(el => <Uid uid={el.to} />)}

            </Container>

            <UidAdd />
        </div>
    )
}

const mapStateToProps = state => {
    return {
        uids: state.chat.uids
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchUids: (uid, token) => dispatch(fetchUsersTextedAsync(uid, token))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Index)