import React from 'react'
import Navbar from '../components/navbar'

import {
    makeStyles,
    Container,
    Typography,
    Grid,
    Paper
} from '@material-ui/core'

// importing images 
import chat from '../images/chat.svg'

const useStyle = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(6)
    },
    title: {
        fontFamily: 'Metal Mania'
    },
    paper: {
        padding: theme.spacing(2)
    }
}))

const Landing = props => {
    const classes = useStyle()
    return (
        <div>
            <Navbar />

            <Container className={classes.root}>
                <Paper className={classes.paper} variant="outlined" >
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <div>
                                <img src={chat} alt="" width="100%" />
                            </div>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <div className={classes.root}>
                                <Typography variant="h5" className={classes.title} align="center">
                                    Right !
                            </Typography>

                                <Typography variant="h5" align="center">
                                    Chat with your college mates with just thier UID!!
                            </Typography>
                            </div>
                        </Grid>
                    </Grid>
                </Paper>
            </Container>

        </div>
    )
}

export default Landing