import React from 'react'

import {
    Container,
    Typography,
    makeStyles,
    Grid,
    Paper
} from '@material-ui/core'

const useStyle = makeStyles(theme => ({
    root: {
        padding: theme.spacing(1, 2)
    },
    paper: {
        padding: theme.spacing(1),
        backgroundColor: '#eeeeee',
        margin: theme.spacing(1,2)
    }
}))

const Text = props => {
    const classes = useStyle()

    return (
        <div>
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <Container>
                        <Paper className={classes.paper} variant="outlined"> 
                            <Typography align={(props.from === localStorage.getItem('uid') ? "right" : "left")}>
                                {props.message}
                            </Typography>
                        </Paper>
                    </Container>
                </Grid>
            </Grid>
        </div>
    )

}


export default Text