import React from 'react'

import {
    Paper,
    makeStyles,
    Typography
} from '@material-ui/core'

import {useHistory} from 'react-router-dom'

const useStyle = makeStyles(theme => ({
    root: {
        margin: theme.spacing(2,1),
        cursor: 'pointer'
    },
    body: {
        padding: theme.spacing(1,2)
    }
}))

const Uids = props => {

    const classes = useStyle()
    const location = useHistory()

    return (
        <div className={classes.root}>
            <Paper 
            variant="outlined" 
            className={classes.body}
            onClick={() => {
                location.push(`/chat/${props.uid}`)
            }}
            >
                <Typography align="center" variant="h5">
                    {props.uid}
                </Typography>
            </Paper>
        </div>
    )
}

export default Uids