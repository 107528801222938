import Axios from 'axios'
import _ from 'lodash'
import {
    CHAT_URL
} from '../../constants/urls'

import {
    FETCH_USERS_TEXTED
} from '../constants'


const fetchUsersTexted = data => {
    return {
        type: FETCH_USERS_TEXTED,
        payload: data
    }
}


export const fetchUsersTextedAsync = (uid, token) => {
    return dispatch => {
        Axios({
            method: 'GET',
            url: CHAT_URL + `?uid=${uid}`,
            headers: { Authorization: `Bearer ${token}` }
        }).then(res => {
            let allUids = _.unionBy(res.data.sent, 'to')
            dispatch(fetchUsersTexted(allUids))
        }).catch(err => {
            console.log(err)
        })
    }
}